import './App.css';

function App() {
  return (
    <div className="App">
      <h1>DID <a href="https://downdetector.com" target="_blank" rel="noreferrer noopener">downdetector.com </a> GO DOWN?!?</h1>
      <h2>It did for a bit, but it's back up now. 06/21/22</h2>
    </div>
  );
}

export default App;